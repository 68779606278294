customElements.define(
	'tag-loading',
	class extends HTMLElement {
		constructor() {
			super()

			const shadowRoot = this.attachShadow({ mode: 'open' })
			shadowRoot.innerHTML = `
    <style>
      :host-context(tag-loading) {
        --progressColor: #ff7f40
      }

      .tg-loader {
        transform: rotateZ(-90deg);
        animation: loader-spin 1.4s linear infinite;
        will-change: transform;
        backface-visibility: hidden;
        perspective: 1000px;
        width: 100%;
        height: 100%;
      }

      .tg-loader circle {
        stroke: #f3f3f3;
        stroke-width: 3;
        stroke-dashoffset: 0;
        will-change: stroke-dashoffset;
        backface-visibility: hidden;
        perspective: 1000px;
        stroke-dasharray: 0;
      }

      .tg-loader #bar {
        stroke: var(--progressColor);
        animation: loader-dash 1.4s ease-in-out infinite;

        stroke-dasharray: 80px, 200px;
        stroke-dashoffset: 0px;

        will-change: stroke-dasharray, stroke-dashoffset;
      }

      @keyframes loader-dash {
        0% {
          stroke-dasharray: 1px, 200px;
          stroke-dashoffset: 0px;
        }
        50% {
          stroke-dasharray: 100px, 200px;
          stroke-dashoffset: -15px;
        }
        100% {
          stroke-dasharray: 100px, 200px;
          stroke-dashoffset: -125px;
        }
      }

      @keyframes loader-spin {
        0% {
          transform-origin: 50% 50%;
        }
        100% {
          transform: rotate(360deg);
        }
      }
    </style>

    <svg class="tg-loader" width="100" height="100" viewBox="22 22 44 44">
      <circle r="20.2" cx="44" cy="44" fill="none"></circle>
      <circle id="bar" r="20.2" cx="44" cy="44" fill="none"></circle>
    </svg>
    `
		}
	}
)
